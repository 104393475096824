import * as Types from './types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export const RunnerFragmentDoc = gql`
    fragment Runner on Runner {
  id
  name
  slug
  avatarUrl
  isMember
  neverRun
  verified
  pronouns
  sessionRoles
  roles
}
    `;
export const CheerFragmentDoc = gql`
    fragment Cheer on Cheer {
  id
  runner {
    ...Runner
  }
  recipient {
    ...Runner
  }
}
    ${RunnerFragmentDoc}`;
export const CheerableFragmentDoc = gql`
    fragment Cheerable on Cheerable {
  id
  cheers {
    ...Cheer
  }
}
    ${CheerFragmentDoc}`;
export const SessionFragmentDoc = gql`
    fragment Session on Session {
  id
  slug
  title
  strapline
  registerMax
  type
  imageUrl
  coverImageUrl
  startedAt
  duration
}
    `;
export const CommentFragmentDoc = gql`
    fragment Comment on Comment {
  id
  content
  runner {
    ...Runner
  }
  createdAt
}
    ${RunnerFragmentDoc}`;
export const CommentableFragmentDoc = gql`
    fragment Commentable on Commentable {
  id
  comments {
    ...Comment
  }
}
    ${CommentFragmentDoc}`;
export const AreaFragmentDoc = gql`
    fragment Area on Area {
  id
  name
  slug
  socialVisits
}
    `;
export const ReportFragmentDoc = gql`
    fragment Report on Report {
  id
  slug
  title
  description
  imageUrl
  coverImageUrl
  publishedAt
  writtenBy {
    ...Runner
  }
  pictures
}
    ${RunnerFragmentDoc}`;
export const NewsfeedAttendanceFragmentDoc = gql`
    fragment NewsfeedAttendance on NewsfeedAttendance {
  id
  time
  ...Cheerable
  session {
    ...Session
    ...Commentable
    area {
      ...Area
    }
    report {
      ...Report
      ...Commentable
    }
  }
  runners {
    ...Runner
  }
  __typename
}
    ${CheerableFragmentDoc}
${SessionFragmentDoc}
${CommentableFragmentDoc}
${AreaFragmentDoc}
${ReportFragmentDoc}
${RunnerFragmentDoc}`;
export const BadgeFragmentDoc = gql`
    fragment Badge on Badge {
  id
  name
  description
  imageUrl
}
    `;
export const NewsfeedAwardFragmentDoc = gql`
    fragment NewsfeedAward on NewsfeedAward {
  id
  time
  description
  ...Cheerable
  ...Commentable
  badge {
    ...Badge
  }
  runner {
    ...Runner
  }
  __typename
}
    ${CheerableFragmentDoc}
${CommentableFragmentDoc}
${BadgeFragmentDoc}
${RunnerFragmentDoc}`;
export const NewsfeedCoachVisitFragmentDoc = gql`
    fragment NewsfeedCoachVisit on NewsfeedCoachVisit {
  id
  time
  ...Cheerable
  ...Commentable
  runner {
    ...Runner
  }
  __typename
}
    ${CheerableFragmentDoc}
${CommentableFragmentDoc}
${RunnerFragmentDoc}`;
export const AddressFragmentDoc = gql`
    fragment Address on Address {
  id
  line1
  line2
  city
  postcode
  lat
  lng
}
    `;
export const NewsfeedRegistrationFragmentDoc = gql`
    fragment NewsfeedRegistration on NewsfeedRegistration {
  id
  time
  ...Cheerable
  session {
    ...Session
    ...Commentable
    registerMax
    signups {
      ...Runner
    }
    address {
      ...Address
    }
    area {
      ...Area
    }
  }
  runners {
    ...Runner
  }
  __typename
}
    ${CheerableFragmentDoc}
${SessionFragmentDoc}
${CommentableFragmentDoc}
${RunnerFragmentDoc}
${AddressFragmentDoc}
${AreaFragmentDoc}`;
export const NewsfeedWorkoutFragmentDoc = gql`
    fragment NewsfeedWorkout on NewsfeedWorkout {
  id
  name
  time
  type
  distance
  duration
  uri
  provider
  averagePace
  ...Cheerable
  ...Commentable
  runner {
    ...Runner
  }
  __typename
}
    ${CheerableFragmentDoc}
${CommentableFragmentDoc}
${RunnerFragmentDoc}`;
export const NewsfeedItemFragmentDoc = gql`
    fragment NewsfeedItem on NewsfeedItem {
  ... on NewsfeedAttendance {
    ...NewsfeedAttendance
  }
  ... on NewsfeedAward {
    ...NewsfeedAward
  }
  ... on NewsfeedCoachVisit {
    ...NewsfeedCoachVisit
  }
  ... on NewsfeedRegistration {
    ...NewsfeedRegistration
  }
  ... on NewsfeedWorkout {
    ...NewsfeedWorkout
  }
}
    ${NewsfeedAttendanceFragmentDoc}
${NewsfeedAwardFragmentDoc}
${NewsfeedCoachVisitFragmentDoc}
${NewsfeedRegistrationFragmentDoc}
${NewsfeedWorkoutFragmentDoc}`;
export const NewsfeedFragmentDoc = gql`
    fragment Newsfeed on Newsfeed {
  id
  page
  limit
  more
  items {
    ...NewsfeedItem
  }
}
    ${NewsfeedItemFragmentDoc}`;
export const AddressSearchResultFragmentDoc = gql`
    fragment AddressSearchResult on AddressSearchResult {
  id
  postcode
  line1
  line2
  city
  county
  country
  area {
    id
    name
    active
    slug
    socialVisits
  }
}
    `;
export const BeneficiaryFragmentDoc = gql`
    fragment Beneficiary on Beneficiary {
  id
  name
}
    `;
export const CtaFragmentDoc = gql`
    fragment CTA on CTA {
  id
  title
  description
  icon
  url
  actionText
  dismissText
}
    `;
export const CoachFragmentDoc = gql`
    fragment Coach on Coach {
  id
  name
}
    `;
export const CoachReferralFragmentDoc = gql`
    fragment CoachReferral on Coach {
  area {
    ...Area
  }
  availableForVisit
  distanceFromRunner
  id
  interests
  lat
  lng
  name
  reasonForReferral
  homeRiskAssesed
  additionalInfo
}
    ${AreaFragmentDoc}`;
export const EmploymentFragmentDoc = gql`
    fragment Employment on Employment {
  id
  startedAt
  employer {
    id
    name
    imageUrl
    slug
  }
}
    `;
export const CurrentRunnerFragmentDoc = gql`
    fragment CurrentRunner on CurrentRunner {
  id
  name
  bio
  postcode
  slug
  pronouns
  avatarUrl
  roles
  employment {
    ...Employment
  }
  homeArea {
    ...Area
    socialVisits
    pairingVerifiedRunnersCount
  }
  areas {
    ...Area
  }
  isMember
  neverRun
  verified
  featureFlags
  needsToGetCoachVerified
}
    ${EmploymentFragmentDoc}
${AreaFragmentDoc}`;
export const TrainingStatusFragmentDoc = gql`
    fragment TrainingStatus on RunnerTrainingStatus {
  id
  slug
  status
}
    `;
export const CurrentRunnerTrainingStatusFragmentDoc = gql`
    fragment CurrentRunnerTrainingStatus on CurrentRunner {
  id
  trainingStatuses {
    ...TrainingStatus
  }
}
    ${TrainingStatusFragmentDoc}`;
export const FollowStatusFragmentDoc = gql`
    fragment FollowStatus on Runner {
  id
  followsYou
  followedByYou
  blockedByYou
}
    `;
export const GroupFitnessActivityFragmentDoc = gql`
    fragment GroupFitnessActivity on GroupFitnessActivity {
  id
  type
  distance
  startedAt
  description
  address {
    ...Address
  }
}
    ${AddressFragmentDoc}`;
export const MenuOptionFragmentDoc = gql`
    fragment MenuOption on MenuOption {
  id
  label
  url
  icon
  completed
  disabled
  method
}
    `;
export const NotificationFragmentDoc = gql`
    fragment Notification on Notification {
  id
  content
  url
  imageUrl
  read
  createdAt
}
    `;
export const OrganisationFragmentDoc = gql`
    fragment Organisation on Organisation {
  id
  slug
  strapline
  name
}
    `;
export const RestrictedBeneficiaryFragmentDoc = gql`
    fragment RestrictedBeneficiary on RestrictedBeneficiary {
  id
  slug
  name
  phoneNumber
  dateOfBirth
  area {
    ...Area
  }
  address {
    ...Address
  }
}
    ${AreaFragmentDoc}
${AddressFragmentDoc}`;
export const RunnerStatsFragmentDoc = gql`
    fragment RunnerStats on RunnerStats {
  id
  runs
  goodDeeds
  cheersReceived
  cheersGiven
}
    `;
export const RunnerStatusFragmentDoc = gql`
    fragment RunnerStatus on Runner {
  id
  isMember
  neverRun
  verified
  roles
}
    `;
export const RunnerStreakFragmentDoc = gql`
    fragment RunnerStreak on RunnerStreak {
  id
  title
  description
  status
  imageUrl
  count
  verified
  current
}
    `;
export const SessionCardFragmentDoc = gql`
    fragment SessionCard on Session {
  ...Session
  address {
    ...Address
  }
  signups {
    ...Runner
  }
  area {
    ...Area
  }
  report {
    ...Report
  }
  signedUp
  coverImageUrl
}
    ${SessionFragmentDoc}
${AddressFragmentDoc}
${RunnerFragmentDoc}
${AreaFragmentDoc}
${ReportFragmentDoc}`;
export const SessionRiskFragmentDoc = gql`
    fragment SessionRisk on SessionRisk {
  id
  name
  description
  imageUrl
}
    `;
export const StoryFragmentDoc = gql`
    fragment Story on Story {
  id
  area {
    ...Area
  }
  title
  body
  imageUrl
  publishedAt
  strapline
  slug
  author {
    ...Runner
  }
  discardedAt
  createdAt
}
    ${AreaFragmentDoc}
${RunnerFragmentDoc}`;
export const TaskForceStatsFragmentDoc = gql`
    fragment TaskForceStats on RunnerStats {
  id
  sessionsLed
  sessionsListed
  sessionsBackmarked
  sessionsPhotographed
  walksLed
  reportsWritten
}
    `;
export const TaskRequestFragmentDoc = gql`
    fragment TaskRequest on TaskRequest {
  id
  slug
  title
  outcome
  deadline
  registerMin
  registerMax
  lat
  lng
  potentialTimes
}
    `;
export const TaskRequestCardFragmentDoc = gql`
    fragment TaskRequestCard on TaskRequest {
  ...TaskRequest
  signedUp
  declined
  area {
    ...Area
  }
  reservations {
    id
    runners {
      ...Runner
    }
    startedAt
  }
}
    ${TaskRequestFragmentDoc}
${AreaFragmentDoc}
${RunnerFragmentDoc}`;
export const TaskforceRoleFragmentDoc = gql`
    fragment TaskforceRole on TaskforceRole {
  id
  name
  description
  slug
  runner {
    ...Runner
  }
}
    ${RunnerFragmentDoc}`;
export const TestPairingFragmentDoc = gql`
    fragment TestPairing on TestPairing {
  acceptedAt
  coach {
    id
    name
  }
  completedAt
  id
  notesForRunner
  rejectedAt
  rejectionNotes
  scheduledAt
  runnerVisitFeedback
  runnerVisitNotes
  runnerWantsPairing
  cancelledAt
  cancellationNotes
}
    `;
export const TrainingFragmentDoc = gql`
    fragment Training on Training {
  id
  slug
  name
  description
}
    `;
export const TrainingModuleFragmentDoc = gql`
    fragment TrainingModule on TrainingModule {
  id
  name
  description
  order
}
    `;
export const TrainingModuleQuestionFragmentDoc = gql`
    fragment TrainingModuleQuestion on TrainingModuleQuestion {
  id
  question
  description
  type
  order
}
    `;
export const TrainingModuleSectionFragmentDoc = gql`
    fragment TrainingModuleSection on TrainingModuleSection {
  id
  name
  description
  order
}
    `;
export const AuthDocument = gql`
    query Auth {
  user: currentUser {
    id
    email
  }
  runner: currentRunner {
    ...CurrentRunner
    donation {
      id
      giftaid
    }
    needsToGetMissionVerified
    needsToGetCoachVerified
    pairing {
      id
      coach {
        ...Coach
      }
    }
  }
  referrer: currentReferrer {
    id
    name
  }
}
    ${CurrentRunnerFragmentDoc}
${CoachFragmentDoc}`;

/**
 * __useAuthQuery__
 *
 * To run a query within a React component, call `useAuthQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthQuery(baseOptions?: Apollo.QueryHookOptions<Types.AuthQuery, Types.AuthQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AuthQuery, Types.AuthQueryVariables>(AuthDocument, options);
      }
export function useAuthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AuthQuery, Types.AuthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AuthQuery, Types.AuthQueryVariables>(AuthDocument, options);
        }
export type AuthQueryHookResult = ReturnType<typeof useAuthQuery>;
export type AuthLazyQueryHookResult = ReturnType<typeof useAuthLazyQuery>;
export type AuthQueryResult = Apollo.QueryResult<Types.AuthQuery, Types.AuthQueryVariables>;
export const AddressSearchDocument = gql`
    query AddressSearch($query: String!) {
  results: autocompleteAddress(query: $query) {
    ...AddressSearchResult
  }
}
    ${AddressSearchResultFragmentDoc}`;

/**
 * __useAddressSearchQuery__
 *
 * To run a query within a React component, call `useAddressSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAddressSearchQuery(baseOptions: Apollo.QueryHookOptions<Types.AddressSearchQuery, Types.AddressSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AddressSearchQuery, Types.AddressSearchQueryVariables>(AddressSearchDocument, options);
      }
export function useAddressSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AddressSearchQuery, Types.AddressSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AddressSearchQuery, Types.AddressSearchQueryVariables>(AddressSearchDocument, options);
        }
export type AddressSearchQueryHookResult = ReturnType<typeof useAddressSearchQuery>;
export type AddressSearchLazyQueryHookResult = ReturnType<typeof useAddressSearchLazyQuery>;
export type AddressSearchQueryResult = Apollo.QueryResult<Types.AddressSearchQuery, Types.AddressSearchQueryVariables>;
export const AreaSearchDocument = gql`
    query AreaSearch {
  areas {
    ...Area
  }
}
    ${AreaFragmentDoc}`;

/**
 * __useAreaSearchQuery__
 *
 * To run a query within a React component, call `useAreaSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useAreaSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAreaSearchQuery({
 *   variables: {
 *   },
 * });
 */
export function useAreaSearchQuery(baseOptions?: Apollo.QueryHookOptions<Types.AreaSearchQuery, Types.AreaSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AreaSearchQuery, Types.AreaSearchQueryVariables>(AreaSearchDocument, options);
      }
export function useAreaSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AreaSearchQuery, Types.AreaSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AreaSearchQuery, Types.AreaSearchQueryVariables>(AreaSearchDocument, options);
        }
export type AreaSearchQueryHookResult = ReturnType<typeof useAreaSearchQuery>;
export type AreaSearchLazyQueryHookResult = ReturnType<typeof useAreaSearchLazyQuery>;
export type AreaSearchQueryResult = Apollo.QueryResult<Types.AreaSearchQuery, Types.AreaSearchQueryVariables>;
export const BeneficiarySearchDocument = gql`
    query BeneficiarySearch($query: String!) {
  results: autocompleteBeneficiary(query: $query) {
    ...RestrictedBeneficiary
    address {
      ...Address
    }
  }
}
    ${RestrictedBeneficiaryFragmentDoc}
${AddressFragmentDoc}`;

/**
 * __useBeneficiarySearchQuery__
 *
 * To run a query within a React component, call `useBeneficiarySearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useBeneficiarySearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBeneficiarySearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useBeneficiarySearchQuery(baseOptions: Apollo.QueryHookOptions<Types.BeneficiarySearchQuery, Types.BeneficiarySearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BeneficiarySearchQuery, Types.BeneficiarySearchQueryVariables>(BeneficiarySearchDocument, options);
      }
export function useBeneficiarySearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BeneficiarySearchQuery, Types.BeneficiarySearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BeneficiarySearchQuery, Types.BeneficiarySearchQueryVariables>(BeneficiarySearchDocument, options);
        }
export type BeneficiarySearchQueryHookResult = ReturnType<typeof useBeneficiarySearchQuery>;
export type BeneficiarySearchLazyQueryHookResult = ReturnType<typeof useBeneficiarySearchLazyQuery>;
export type BeneficiarySearchQueryResult = Apollo.QueryResult<Types.BeneficiarySearchQuery, Types.BeneficiarySearchQueryVariables>;
export const NotificationsDocument = gql`
    query Notifications {
  runner: currentRunner {
    id
    notifications {
      ...Notification
    }
  }
}
    ${NotificationFragmentDoc}`;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<Types.NotificationsQuery, Types.NotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.NotificationsQuery, Types.NotificationsQueryVariables>(NotificationsDocument, options);
      }
export function useNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.NotificationsQuery, Types.NotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.NotificationsQuery, Types.NotificationsQueryVariables>(NotificationsDocument, options);
        }
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsQueryResult = Apollo.QueryResult<Types.NotificationsQuery, Types.NotificationsQueryVariables>;
export const NewsfeedDocument = gql`
    query Newsfeed($type: NewsfeedType!, $runnerId: ID, $page: Int) {
  newsfeed(type: $type, runnerId: $runnerId, page: $page) {
    ...Newsfeed
  }
}
    ${NewsfeedFragmentDoc}`;

/**
 * __useNewsfeedQuery__
 *
 * To run a query within a React component, call `useNewsfeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsfeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsfeedQuery({
 *   variables: {
 *      type: // value for 'type'
 *      runnerId: // value for 'runnerId'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useNewsfeedQuery(baseOptions: Apollo.QueryHookOptions<Types.NewsfeedQuery, Types.NewsfeedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.NewsfeedQuery, Types.NewsfeedQueryVariables>(NewsfeedDocument, options);
      }
export function useNewsfeedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.NewsfeedQuery, Types.NewsfeedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.NewsfeedQuery, Types.NewsfeedQueryVariables>(NewsfeedDocument, options);
        }
export type NewsfeedQueryHookResult = ReturnType<typeof useNewsfeedQuery>;
export type NewsfeedLazyQueryHookResult = ReturnType<typeof useNewsfeedLazyQuery>;
export type NewsfeedQueryResult = Apollo.QueryResult<Types.NewsfeedQuery, Types.NewsfeedQueryVariables>;
export const ReportsFeedDocument = gql`
    query ReportsFeed($areaId: ID, $organisationId: ID, $before: ISO8601DateTime, $runnerId: ID, $sessionType: String) {
  results: reports(
    areaId: $areaId
    organisationId: $organisationId
    before: $before
    runnerId: $runnerId
    sessionType: $sessionType
  ) {
    before
    more
    areaId
    reports {
      ...Report
      session {
        ...Session
        area {
          ...Area
        }
        signups {
          id
          slug
          name
          avatarUrl
          isMember
          neverRun
          verified
          pronouns
          roles
        }
      }
    }
  }
}
    ${ReportFragmentDoc}
${SessionFragmentDoc}
${AreaFragmentDoc}`;

/**
 * __useReportsFeedQuery__
 *
 * To run a query within a React component, call `useReportsFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportsFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportsFeedQuery({
 *   variables: {
 *      areaId: // value for 'areaId'
 *      organisationId: // value for 'organisationId'
 *      before: // value for 'before'
 *      runnerId: // value for 'runnerId'
 *      sessionType: // value for 'sessionType'
 *   },
 * });
 */
export function useReportsFeedQuery(baseOptions?: Apollo.QueryHookOptions<Types.ReportsFeedQuery, Types.ReportsFeedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ReportsFeedQuery, Types.ReportsFeedQueryVariables>(ReportsFeedDocument, options);
      }
export function useReportsFeedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ReportsFeedQuery, Types.ReportsFeedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ReportsFeedQuery, Types.ReportsFeedQueryVariables>(ReportsFeedDocument, options);
        }
export type ReportsFeedQueryHookResult = ReturnType<typeof useReportsFeedQuery>;
export type ReportsFeedLazyQueryHookResult = ReturnType<typeof useReportsFeedLazyQuery>;
export type ReportsFeedQueryResult = Apollo.QueryResult<Types.ReportsFeedQuery, Types.ReportsFeedQueryVariables>;
export const RunnerSearchDocument = gql`
    query RunnerSearch($query: String!) {
  results: autocompleteRunner(query: $query) {
    ...Runner
  }
}
    ${RunnerFragmentDoc}`;

/**
 * __useRunnerSearchQuery__
 *
 * To run a query within a React component, call `useRunnerSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunnerSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunnerSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useRunnerSearchQuery(baseOptions: Apollo.QueryHookOptions<Types.RunnerSearchQuery, Types.RunnerSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RunnerSearchQuery, Types.RunnerSearchQueryVariables>(RunnerSearchDocument, options);
      }
export function useRunnerSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RunnerSearchQuery, Types.RunnerSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RunnerSearchQuery, Types.RunnerSearchQueryVariables>(RunnerSearchDocument, options);
        }
export type RunnerSearchQueryHookResult = ReturnType<typeof useRunnerSearchQuery>;
export type RunnerSearchLazyQueryHookResult = ReturnType<typeof useRunnerSearchLazyQuery>;
export type RunnerSearchQueryResult = Apollo.QueryResult<Types.RunnerSearchQuery, Types.RunnerSearchQueryVariables>;
export const RunnerTooltipDocument = gql`
    query RunnerTooltip($id: ID!) {
  runner(id: $id) {
    ...Runner
    ...RunnerStatus
    ...FollowStatus
    homeArea {
      ...Area
    }
  }
}
    ${RunnerFragmentDoc}
${RunnerStatusFragmentDoc}
${FollowStatusFragmentDoc}
${AreaFragmentDoc}`;

/**
 * __useRunnerTooltipQuery__
 *
 * To run a query within a React component, call `useRunnerTooltipQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunnerTooltipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunnerTooltipQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRunnerTooltipQuery(baseOptions: Apollo.QueryHookOptions<Types.RunnerTooltipQuery, Types.RunnerTooltipQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.RunnerTooltipQuery, Types.RunnerTooltipQueryVariables>(RunnerTooltipDocument, options);
      }
export function useRunnerTooltipLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.RunnerTooltipQuery, Types.RunnerTooltipQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.RunnerTooltipQuery, Types.RunnerTooltipQueryVariables>(RunnerTooltipDocument, options);
        }
export type RunnerTooltipQueryHookResult = ReturnType<typeof useRunnerTooltipQuery>;
export type RunnerTooltipLazyQueryHookResult = ReturnType<typeof useRunnerTooltipLazyQuery>;
export type RunnerTooltipQueryResult = Apollo.QueryResult<Types.RunnerTooltipQuery, Types.RunnerTooltipQueryVariables>;
export const SessionSearchDocument = gql`
    query SessionSearch($query: String!) {
  results: autocompleteSession(query: $query) {
    ...Session
  }
}
    ${SessionFragmentDoc}`;

/**
 * __useSessionSearchQuery__
 *
 * To run a query within a React component, call `useSessionSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSessionSearchQuery(baseOptions: Apollo.QueryHookOptions<Types.SessionSearchQuery, Types.SessionSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SessionSearchQuery, Types.SessionSearchQueryVariables>(SessionSearchDocument, options);
      }
export function useSessionSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SessionSearchQuery, Types.SessionSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SessionSearchQuery, Types.SessionSearchQueryVariables>(SessionSearchDocument, options);
        }
export type SessionSearchQueryHookResult = ReturnType<typeof useSessionSearchQuery>;
export type SessionSearchLazyQueryHookResult = ReturnType<typeof useSessionSearchLazyQuery>;
export type SessionSearchQueryResult = Apollo.QueryResult<Types.SessionSearchQuery, Types.SessionSearchQueryVariables>;
export const SessionSignupDialogTrainingDocument = gql`
    query SessionSignupDialogTraining($slug: String!) {
  training(slug: $slug) {
    ...Training
    modules {
      ...TrainingModule
      sections {
        ...TrainingModuleSection
      }
      questions {
        ...TrainingModuleQuestion
      }
    }
  }
}
    ${TrainingFragmentDoc}
${TrainingModuleFragmentDoc}
${TrainingModuleSectionFragmentDoc}
${TrainingModuleQuestionFragmentDoc}`;

/**
 * __useSessionSignupDialogTrainingQuery__
 *
 * To run a query within a React component, call `useSessionSignupDialogTrainingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionSignupDialogTrainingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionSignupDialogTrainingQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useSessionSignupDialogTrainingQuery(baseOptions: Apollo.QueryHookOptions<Types.SessionSignupDialogTrainingQuery, Types.SessionSignupDialogTrainingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SessionSignupDialogTrainingQuery, Types.SessionSignupDialogTrainingQueryVariables>(SessionSignupDialogTrainingDocument, options);
      }
export function useSessionSignupDialogTrainingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SessionSignupDialogTrainingQuery, Types.SessionSignupDialogTrainingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SessionSignupDialogTrainingQuery, Types.SessionSignupDialogTrainingQueryVariables>(SessionSignupDialogTrainingDocument, options);
        }
export type SessionSignupDialogTrainingQueryHookResult = ReturnType<typeof useSessionSignupDialogTrainingQuery>;
export type SessionSignupDialogTrainingLazyQueryHookResult = ReturnType<typeof useSessionSignupDialogTrainingLazyQuery>;
export type SessionSignupDialogTrainingQueryResult = Apollo.QueryResult<Types.SessionSignupDialogTrainingQuery, Types.SessionSignupDialogTrainingQueryVariables>;
export const SessionSignupDialogDocument = gql`
    query SessionSignupDialog {
  runner: currentRunner {
    ...CurrentRunner
    ...CurrentRunnerTrainingStatus
  }
}
    ${CurrentRunnerFragmentDoc}
${CurrentRunnerTrainingStatusFragmentDoc}`;

/**
 * __useSessionSignupDialogQuery__
 *
 * To run a query within a React component, call `useSessionSignupDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionSignupDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionSignupDialogQuery({
 *   variables: {
 *   },
 * });
 */
export function useSessionSignupDialogQuery(baseOptions?: Apollo.QueryHookOptions<Types.SessionSignupDialogQuery, Types.SessionSignupDialogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SessionSignupDialogQuery, Types.SessionSignupDialogQueryVariables>(SessionSignupDialogDocument, options);
      }
export function useSessionSignupDialogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SessionSignupDialogQuery, Types.SessionSignupDialogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SessionSignupDialogQuery, Types.SessionSignupDialogQueryVariables>(SessionSignupDialogDocument, options);
        }
export type SessionSignupDialogQueryHookResult = ReturnType<typeof useSessionSignupDialogQuery>;
export type SessionSignupDialogLazyQueryHookResult = ReturnType<typeof useSessionSignupDialogLazyQuery>;
export type SessionSignupDialogQueryResult = Apollo.QueryResult<Types.SessionSignupDialogQuery, Types.SessionSignupDialogQueryVariables>;
export const SessionsFeedCalendarDocument = gql`
    query SessionsFeedCalendar($areaIds: [ID!]!, $postcode: String, $maxDistance: Int!, $from: ISO8601DateTime!, $sessionsCursorDate: ISO8601DateTime, $sessionsCursorId: ID, $taskRequestsCursorDate: ISO8601DateTime, $taskRequestsCursorId: ID, $limit: Int) {
  runner: currentRunner {
    id
    upcomingSessions {
      id
      startedAt
    }
  }
  sessions: sessionsV2(
    areaIds: $areaIds
    postcode: $postcode
    maxDistance: $maxDistance
    from: $from
    cursorDate: $sessionsCursorDate
    cursorId: $sessionsCursorId
    limit: $limit
  ) {
    more
    results {
      id
      startedAt
      type
      signedUp
    }
  }
  taskRequests(
    areaIds: $areaIds
    postcode: $postcode
    maxDistance: $maxDistance
    from: $from
    cursorDate: $taskRequestsCursorDate
    cursorId: $taskRequestsCursorId
    limit: $limit
  ) {
    more
    results {
      id
      declined
      potentialTimes
    }
  }
}
    `;

/**
 * __useSessionsFeedCalendarQuery__
 *
 * To run a query within a React component, call `useSessionsFeedCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionsFeedCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionsFeedCalendarQuery({
 *   variables: {
 *      areaIds: // value for 'areaIds'
 *      postcode: // value for 'postcode'
 *      maxDistance: // value for 'maxDistance'
 *      from: // value for 'from'
 *      sessionsCursorDate: // value for 'sessionsCursorDate'
 *      sessionsCursorId: // value for 'sessionsCursorId'
 *      taskRequestsCursorDate: // value for 'taskRequestsCursorDate'
 *      taskRequestsCursorId: // value for 'taskRequestsCursorId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSessionsFeedCalendarQuery(baseOptions: Apollo.QueryHookOptions<Types.SessionsFeedCalendarQuery, Types.SessionsFeedCalendarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SessionsFeedCalendarQuery, Types.SessionsFeedCalendarQueryVariables>(SessionsFeedCalendarDocument, options);
      }
export function useSessionsFeedCalendarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SessionsFeedCalendarQuery, Types.SessionsFeedCalendarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SessionsFeedCalendarQuery, Types.SessionsFeedCalendarQueryVariables>(SessionsFeedCalendarDocument, options);
        }
export type SessionsFeedCalendarQueryHookResult = ReturnType<typeof useSessionsFeedCalendarQuery>;
export type SessionsFeedCalendarLazyQueryHookResult = ReturnType<typeof useSessionsFeedCalendarLazyQuery>;
export type SessionsFeedCalendarQueryResult = Apollo.QueryResult<Types.SessionsFeedCalendarQuery, Types.SessionsFeedCalendarQueryVariables>;
export const SessionsFeedDocument = gql`
    query SessionsFeed($types: [String!]!, $areaIds: [ID!]!, $postcode: String, $maxDistance: Int!, $from: ISO8601DateTime!, $sessionsCursorDate: ISO8601DateTime, $sessionsCursorId: ID, $taskRequestsCursorDate: ISO8601DateTime, $taskRequestsCursorId: ID, $limit: Int) {
  sessions: sessionsV2(
    types: $types
    areaIds: $areaIds
    postcode: $postcode
    maxDistance: $maxDistance
    from: $from
    cursorDate: $sessionsCursorDate
    cursorId: $sessionsCursorId
    limit: $limit
  ) {
    more
    results {
      ...SessionCard
    }
  }
  taskRequests(
    types: $types
    areaIds: $areaIds
    postcode: $postcode
    maxDistance: $maxDistance
    from: $from
    cursorDate: $taskRequestsCursorDate
    cursorId: $taskRequestsCursorId
    limit: $limit
  ) {
    more
    results {
      ...TaskRequestCard
    }
  }
}
    ${SessionCardFragmentDoc}
${TaskRequestCardFragmentDoc}`;

/**
 * __useSessionsFeedQuery__
 *
 * To run a query within a React component, call `useSessionsFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionsFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionsFeedQuery({
 *   variables: {
 *      types: // value for 'types'
 *      areaIds: // value for 'areaIds'
 *      postcode: // value for 'postcode'
 *      maxDistance: // value for 'maxDistance'
 *      from: // value for 'from'
 *      sessionsCursorDate: // value for 'sessionsCursorDate'
 *      sessionsCursorId: // value for 'sessionsCursorId'
 *      taskRequestsCursorDate: // value for 'taskRequestsCursorDate'
 *      taskRequestsCursorId: // value for 'taskRequestsCursorId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSessionsFeedQuery(baseOptions: Apollo.QueryHookOptions<Types.SessionsFeedQuery, Types.SessionsFeedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SessionsFeedQuery, Types.SessionsFeedQueryVariables>(SessionsFeedDocument, options);
      }
export function useSessionsFeedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SessionsFeedQuery, Types.SessionsFeedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SessionsFeedQuery, Types.SessionsFeedQueryVariables>(SessionsFeedDocument, options);
        }
export type SessionsFeedQueryHookResult = ReturnType<typeof useSessionsFeedQuery>;
export type SessionsFeedLazyQueryHookResult = ReturnType<typeof useSessionsFeedLazyQuery>;
export type SessionsFeedQueryResult = Apollo.QueryResult<Types.SessionsFeedQuery, Types.SessionsFeedQueryVariables>;
export const TaskRequestSearchDocument = gql`
    query TaskRequestSearch($query: String!) {
  results: autocompleteTaskRequest(query: $query) {
    ...TaskRequest
  }
}
    ${TaskRequestFragmentDoc}`;

/**
 * __useTaskRequestSearchQuery__
 *
 * To run a query within a React component, call `useTaskRequestSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskRequestSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskRequestSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useTaskRequestSearchQuery(baseOptions: Apollo.QueryHookOptions<Types.TaskRequestSearchQuery, Types.TaskRequestSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TaskRequestSearchQuery, Types.TaskRequestSearchQueryVariables>(TaskRequestSearchDocument, options);
      }
export function useTaskRequestSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TaskRequestSearchQuery, Types.TaskRequestSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TaskRequestSearchQuery, Types.TaskRequestSearchQueryVariables>(TaskRequestSearchDocument, options);
        }
export type TaskRequestSearchQueryHookResult = ReturnType<typeof useTaskRequestSearchQuery>;
export type TaskRequestSearchLazyQueryHookResult = ReturnType<typeof useTaskRequestSearchLazyQuery>;
export type TaskRequestSearchQueryResult = Apollo.QueryResult<Types.TaskRequestSearchQuery, Types.TaskRequestSearchQueryVariables>;
export const BlockDocument = gql`
    mutation Block($runnerIds: [ID!]!) {
  response: block(runnerIds: $runnerIds) {
    runners {
      ...FollowStatus
    }
  }
}
    ${FollowStatusFragmentDoc}`;
export type BlockMutationFn = Apollo.MutationFunction<Types.BlockMutation, Types.BlockMutationVariables>;

/**
 * __useBlockMutation__
 *
 * To run a mutation, you first call `useBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockMutation, { data, loading, error }] = useBlockMutation({
 *   variables: {
 *      runnerIds: // value for 'runnerIds'
 *   },
 * });
 */
export function useBlockMutation(baseOptions?: Apollo.MutationHookOptions<Types.BlockMutation, Types.BlockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.BlockMutation, Types.BlockMutationVariables>(BlockDocument, options);
      }
export type BlockMutationHookResult = ReturnType<typeof useBlockMutation>;
export type BlockMutationResult = Apollo.MutationResult<Types.BlockMutation>;
export type BlockMutationOptions = Apollo.BaseMutationOptions<Types.BlockMutation, Types.BlockMutationVariables>;
export const CheerDocument = gql`
    mutation Cheer($cheerableActivityId: ID!, $cheerableType: CheerableType!, $runnerIds: [ID!]!) {
  response: cheer(
    cheerableActivityId: $cheerableActivityId
    cheerableType: $cheerableType
    runnerIds: $runnerIds
  ) {
    cheerable {
      ...Cheerable
    }
  }
}
    ${CheerableFragmentDoc}`;
export type CheerMutationFn = Apollo.MutationFunction<Types.CheerMutation, Types.CheerMutationVariables>;

/**
 * __useCheerMutation__
 *
 * To run a mutation, you first call `useCheerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cheerMutation, { data, loading, error }] = useCheerMutation({
 *   variables: {
 *      cheerableActivityId: // value for 'cheerableActivityId'
 *      cheerableType: // value for 'cheerableType'
 *      runnerIds: // value for 'runnerIds'
 *   },
 * });
 */
export function useCheerMutation(baseOptions?: Apollo.MutationHookOptions<Types.CheerMutation, Types.CheerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CheerMutation, Types.CheerMutationVariables>(CheerDocument, options);
      }
export type CheerMutationHookResult = ReturnType<typeof useCheerMutation>;
export type CheerMutationResult = Apollo.MutationResult<Types.CheerMutation>;
export type CheerMutationOptions = Apollo.BaseMutationOptions<Types.CheerMutation, Types.CheerMutationVariables>;
export const CompleteTrainingDocument = gql`
    mutation CompleteTraining($trainingId: ID!, $answers: [TrainingAnswerInput!]!) {
  response: completeTraining(trainingId: $trainingId, answers: $answers) {
    runner {
      ...CurrentRunnerTrainingStatus
    }
    success
  }
}
    ${CurrentRunnerTrainingStatusFragmentDoc}`;
export type CompleteTrainingMutationFn = Apollo.MutationFunction<Types.CompleteTrainingMutation, Types.CompleteTrainingMutationVariables>;

/**
 * __useCompleteTrainingMutation__
 *
 * To run a mutation, you first call `useCompleteTrainingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteTrainingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeTrainingMutation, { data, loading, error }] = useCompleteTrainingMutation({
 *   variables: {
 *      trainingId: // value for 'trainingId'
 *      answers: // value for 'answers'
 *   },
 * });
 */
export function useCompleteTrainingMutation(baseOptions?: Apollo.MutationHookOptions<Types.CompleteTrainingMutation, Types.CompleteTrainingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CompleteTrainingMutation, Types.CompleteTrainingMutationVariables>(CompleteTrainingDocument, options);
      }
export type CompleteTrainingMutationHookResult = ReturnType<typeof useCompleteTrainingMutation>;
export type CompleteTrainingMutationResult = Apollo.MutationResult<Types.CompleteTrainingMutation>;
export type CompleteTrainingMutationOptions = Apollo.BaseMutationOptions<Types.CompleteTrainingMutation, Types.CompleteTrainingMutationVariables>;
export const CreateCommentDocument = gql`
    mutation CreateComment($commentableId: ID!, $commentableType: CommentableType!, $content: String!) {
  response: createComment(
    commentableId: $commentableId
    commentableType: $commentableType
    content: $content
  ) {
    commentable {
      ...Commentable
    }
  }
}
    ${CommentableFragmentDoc}`;
export type CreateCommentMutationFn = Apollo.MutationFunction<Types.CreateCommentMutation, Types.CreateCommentMutationVariables>;

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      commentableId: // value for 'commentableId'
 *      commentableType: // value for 'commentableType'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useCreateCommentMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateCommentMutation, Types.CreateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateCommentMutation, Types.CreateCommentMutationVariables>(CreateCommentDocument, options);
      }
export type CreateCommentMutationHookResult = ReturnType<typeof useCreateCommentMutation>;
export type CreateCommentMutationResult = Apollo.MutationResult<Types.CreateCommentMutation>;
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<Types.CreateCommentMutation, Types.CreateCommentMutationVariables>;
export const DeclineTaskRequestDocument = gql`
    mutation DeclineTaskRequest($taskRequestId: ID!) {
  response: declineTaskRequest(taskRequestId: $taskRequestId) {
    taskRequest {
      ...TaskRequestCard
    }
  }
}
    ${TaskRequestCardFragmentDoc}`;
export type DeclineTaskRequestMutationFn = Apollo.MutationFunction<Types.DeclineTaskRequestMutation, Types.DeclineTaskRequestMutationVariables>;

/**
 * __useDeclineTaskRequestMutation__
 *
 * To run a mutation, you first call `useDeclineTaskRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineTaskRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineTaskRequestMutation, { data, loading, error }] = useDeclineTaskRequestMutation({
 *   variables: {
 *      taskRequestId: // value for 'taskRequestId'
 *   },
 * });
 */
export function useDeclineTaskRequestMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeclineTaskRequestMutation, Types.DeclineTaskRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeclineTaskRequestMutation, Types.DeclineTaskRequestMutationVariables>(DeclineTaskRequestDocument, options);
      }
export type DeclineTaskRequestMutationHookResult = ReturnType<typeof useDeclineTaskRequestMutation>;
export type DeclineTaskRequestMutationResult = Apollo.MutationResult<Types.DeclineTaskRequestMutation>;
export type DeclineTaskRequestMutationOptions = Apollo.BaseMutationOptions<Types.DeclineTaskRequestMutation, Types.DeclineTaskRequestMutationVariables>;
export const DeleteCommentDocument = gql`
    mutation DeleteComment($commentId: ID!) {
  response: deleteComment(commentId: $commentId) {
    commentable {
      ...Commentable
    }
  }
}
    ${CommentableFragmentDoc}`;
export type DeleteCommentMutationFn = Apollo.MutationFunction<Types.DeleteCommentMutation, Types.DeleteCommentMutationVariables>;

/**
 * __useDeleteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentMutation, { data, loading, error }] = useDeleteCommentMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useDeleteCommentMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteCommentMutation, Types.DeleteCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteCommentMutation, Types.DeleteCommentMutationVariables>(DeleteCommentDocument, options);
      }
export type DeleteCommentMutationHookResult = ReturnType<typeof useDeleteCommentMutation>;
export type DeleteCommentMutationResult = Apollo.MutationResult<Types.DeleteCommentMutation>;
export type DeleteCommentMutationOptions = Apollo.BaseMutationOptions<Types.DeleteCommentMutation, Types.DeleteCommentMutationVariables>;
export const DeleteGroupFitnessActivityDocument = gql`
    mutation DeleteGroupFitnessActivity($groupFitnessActivityId: ID!) {
  response: deleteGroupFitnessActivity(
    groupFitnessActivityId: $groupFitnessActivityId
  ) {
    session {
      ...Session
      groupFitnessActivities {
        ...GroupFitnessActivity
      }
    }
  }
}
    ${SessionFragmentDoc}
${GroupFitnessActivityFragmentDoc}`;
export type DeleteGroupFitnessActivityMutationFn = Apollo.MutationFunction<Types.DeleteGroupFitnessActivityMutation, Types.DeleteGroupFitnessActivityMutationVariables>;

/**
 * __useDeleteGroupFitnessActivityMutation__
 *
 * To run a mutation, you first call `useDeleteGroupFitnessActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupFitnessActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupFitnessActivityMutation, { data, loading, error }] = useDeleteGroupFitnessActivityMutation({
 *   variables: {
 *      groupFitnessActivityId: // value for 'groupFitnessActivityId'
 *   },
 * });
 */
export function useDeleteGroupFitnessActivityMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteGroupFitnessActivityMutation, Types.DeleteGroupFitnessActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteGroupFitnessActivityMutation, Types.DeleteGroupFitnessActivityMutationVariables>(DeleteGroupFitnessActivityDocument, options);
      }
export type DeleteGroupFitnessActivityMutationHookResult = ReturnType<typeof useDeleteGroupFitnessActivityMutation>;
export type DeleteGroupFitnessActivityMutationResult = Apollo.MutationResult<Types.DeleteGroupFitnessActivityMutation>;
export type DeleteGroupFitnessActivityMutationOptions = Apollo.BaseMutationOptions<Types.DeleteGroupFitnessActivityMutation, Types.DeleteGroupFitnessActivityMutationVariables>;
export const DismissCtaDocument = gql`
    mutation DismissCta($id: ID!) {
  response: dismissCta(id: $id) {
    id
    currentRunner {
      ...CurrentRunner
      ctas {
        ...CTA
      }
    }
  }
}
    ${CurrentRunnerFragmentDoc}
${CtaFragmentDoc}`;
export type DismissCtaMutationFn = Apollo.MutationFunction<Types.DismissCtaMutation, Types.DismissCtaMutationVariables>;

/**
 * __useDismissCtaMutation__
 *
 * To run a mutation, you first call `useDismissCtaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDismissCtaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dismissCtaMutation, { data, loading, error }] = useDismissCtaMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDismissCtaMutation(baseOptions?: Apollo.MutationHookOptions<Types.DismissCtaMutation, Types.DismissCtaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DismissCtaMutation, Types.DismissCtaMutationVariables>(DismissCtaDocument, options);
      }
export type DismissCtaMutationHookResult = ReturnType<typeof useDismissCtaMutation>;
export type DismissCtaMutationResult = Apollo.MutationResult<Types.DismissCtaMutation>;
export type DismissCtaMutationOptions = Apollo.BaseMutationOptions<Types.DismissCtaMutation, Types.DismissCtaMutationVariables>;
export const FollowDocument = gql`
    mutation Follow($runnerIds: [ID!]!, $message: String!) {
  response: follow(runnerIds: $runnerIds, message: $message) {
    runners {
      ...FollowStatus
    }
  }
}
    ${FollowStatusFragmentDoc}`;
export type FollowMutationFn = Apollo.MutationFunction<Types.FollowMutation, Types.FollowMutationVariables>;

/**
 * __useFollowMutation__
 *
 * To run a mutation, you first call `useFollowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followMutation, { data, loading, error }] = useFollowMutation({
 *   variables: {
 *      runnerIds: // value for 'runnerIds'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useFollowMutation(baseOptions?: Apollo.MutationHookOptions<Types.FollowMutation, Types.FollowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.FollowMutation, Types.FollowMutationVariables>(FollowDocument, options);
      }
export type FollowMutationHookResult = ReturnType<typeof useFollowMutation>;
export type FollowMutationResult = Apollo.MutationResult<Types.FollowMutation>;
export type FollowMutationOptions = Apollo.BaseMutationOptions<Types.FollowMutation, Types.FollowMutationVariables>;
export const JoinWaitingListDocument = gql`
    mutation JoinWaitingList($sessionId: ID!) {
  response: joinWaitingList(sessionId: $sessionId) {
    session {
      id
      onWaitingList
    }
  }
}
    `;
export type JoinWaitingListMutationFn = Apollo.MutationFunction<Types.JoinWaitingListMutation, Types.JoinWaitingListMutationVariables>;

/**
 * __useJoinWaitingListMutation__
 *
 * To run a mutation, you first call `useJoinWaitingListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinWaitingListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinWaitingListMutation, { data, loading, error }] = useJoinWaitingListMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useJoinWaitingListMutation(baseOptions?: Apollo.MutationHookOptions<Types.JoinWaitingListMutation, Types.JoinWaitingListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.JoinWaitingListMutation, Types.JoinWaitingListMutationVariables>(JoinWaitingListDocument, options);
      }
export type JoinWaitingListMutationHookResult = ReturnType<typeof useJoinWaitingListMutation>;
export type JoinWaitingListMutationResult = Apollo.MutationResult<Types.JoinWaitingListMutation>;
export type JoinWaitingListMutationOptions = Apollo.BaseMutationOptions<Types.JoinWaitingListMutation, Types.JoinWaitingListMutationVariables>;
export const LeaveWaitingListDocument = gql`
    mutation LeaveWaitingList($sessionId: ID!) {
  response: leaveWaitingList(sessionId: $sessionId) {
    session {
      id
      onWaitingList
    }
  }
}
    `;
export type LeaveWaitingListMutationFn = Apollo.MutationFunction<Types.LeaveWaitingListMutation, Types.LeaveWaitingListMutationVariables>;

/**
 * __useLeaveWaitingListMutation__
 *
 * To run a mutation, you first call `useLeaveWaitingListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveWaitingListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveWaitingListMutation, { data, loading, error }] = useLeaveWaitingListMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useLeaveWaitingListMutation(baseOptions?: Apollo.MutationHookOptions<Types.LeaveWaitingListMutation, Types.LeaveWaitingListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.LeaveWaitingListMutation, Types.LeaveWaitingListMutationVariables>(LeaveWaitingListDocument, options);
      }
export type LeaveWaitingListMutationHookResult = ReturnType<typeof useLeaveWaitingListMutation>;
export type LeaveWaitingListMutationResult = Apollo.MutationResult<Types.LeaveWaitingListMutation>;
export type LeaveWaitingListMutationOptions = Apollo.BaseMutationOptions<Types.LeaveWaitingListMutation, Types.LeaveWaitingListMutationVariables>;
export const MarkAllNotificationsAsReadDocument = gql`
    mutation MarkAllNotificationsAsRead {
  response: markAllNotificationsAsRead {
    notifications {
      ...Notification
    }
  }
}
    ${NotificationFragmentDoc}`;
export type MarkAllNotificationsAsReadMutationFn = Apollo.MutationFunction<Types.MarkAllNotificationsAsReadMutation, Types.MarkAllNotificationsAsReadMutationVariables>;

/**
 * __useMarkAllNotificationsAsReadMutation__
 *
 * To run a mutation, you first call `useMarkAllNotificationsAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAllNotificationsAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAllNotificationsAsReadMutation, { data, loading, error }] = useMarkAllNotificationsAsReadMutation({
 *   variables: {
 *   },
 * });
 */
export function useMarkAllNotificationsAsReadMutation(baseOptions?: Apollo.MutationHookOptions<Types.MarkAllNotificationsAsReadMutation, Types.MarkAllNotificationsAsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.MarkAllNotificationsAsReadMutation, Types.MarkAllNotificationsAsReadMutationVariables>(MarkAllNotificationsAsReadDocument, options);
      }
export type MarkAllNotificationsAsReadMutationHookResult = ReturnType<typeof useMarkAllNotificationsAsReadMutation>;
export type MarkAllNotificationsAsReadMutationResult = Apollo.MutationResult<Types.MarkAllNotificationsAsReadMutation>;
export type MarkAllNotificationsAsReadMutationOptions = Apollo.BaseMutationOptions<Types.MarkAllNotificationsAsReadMutation, Types.MarkAllNotificationsAsReadMutationVariables>;
export const RequestTestPairingDocument = gql`
    mutation RequestTestPairing($coachId: ID!, $runnerSuggestedTimes: [JSON!]!) {
  response: requestTestPairing(
    coachId: $coachId
    runnerSuggestedTimes: $runnerSuggestedTimes
  ) {
    testPairing {
      ...TestPairing
    }
    errors
  }
}
    ${TestPairingFragmentDoc}`;
export type RequestTestPairingMutationFn = Apollo.MutationFunction<Types.RequestTestPairingMutation, Types.RequestTestPairingMutationVariables>;

/**
 * __useRequestTestPairingMutation__
 *
 * To run a mutation, you first call `useRequestTestPairingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestTestPairingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestTestPairingMutation, { data, loading, error }] = useRequestTestPairingMutation({
 *   variables: {
 *      coachId: // value for 'coachId'
 *      runnerSuggestedTimes: // value for 'runnerSuggestedTimes'
 *   },
 * });
 */
export function useRequestTestPairingMutation(baseOptions?: Apollo.MutationHookOptions<Types.RequestTestPairingMutation, Types.RequestTestPairingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RequestTestPairingMutation, Types.RequestTestPairingMutationVariables>(RequestTestPairingDocument, options);
      }
export type RequestTestPairingMutationHookResult = ReturnType<typeof useRequestTestPairingMutation>;
export type RequestTestPairingMutationResult = Apollo.MutationResult<Types.RequestTestPairingMutation>;
export type RequestTestPairingMutationOptions = Apollo.BaseMutationOptions<Types.RequestTestPairingMutation, Types.RequestTestPairingMutationVariables>;
export const SignUpToSessionDocument = gql`
    mutation SignUpToSession($sessionId: ID!, $groupFitnessActivityId: ID, $taskforceRoleIds: [ID!]!) {
  response: signUpToSession(
    sessionId: $sessionId
    groupFitnessActivityId: $groupFitnessActivityId
    taskforceRoleIds: $taskforceRoleIds
  ) {
    session {
      ...SessionCard
    }
  }
}
    ${SessionCardFragmentDoc}`;
export type SignUpToSessionMutationFn = Apollo.MutationFunction<Types.SignUpToSessionMutation, Types.SignUpToSessionMutationVariables>;

/**
 * __useSignUpToSessionMutation__
 *
 * To run a mutation, you first call `useSignUpToSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpToSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpToSessionMutation, { data, loading, error }] = useSignUpToSessionMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      groupFitnessActivityId: // value for 'groupFitnessActivityId'
 *      taskforceRoleIds: // value for 'taskforceRoleIds'
 *   },
 * });
 */
export function useSignUpToSessionMutation(baseOptions?: Apollo.MutationHookOptions<Types.SignUpToSessionMutation, Types.SignUpToSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.SignUpToSessionMutation, Types.SignUpToSessionMutationVariables>(SignUpToSessionDocument, options);
      }
export type SignUpToSessionMutationHookResult = ReturnType<typeof useSignUpToSessionMutation>;
export type SignUpToSessionMutationResult = Apollo.MutationResult<Types.SignUpToSessionMutation>;
export type SignUpToSessionMutationOptions = Apollo.BaseMutationOptions<Types.SignUpToSessionMutation, Types.SignUpToSessionMutationVariables>;
export const UnSignUpFromSessionDocument = gql`
    mutation UnSignUpFromSession($sessionId: ID!) {
  response: unSignUpFromSession(sessionId: $sessionId) {
    session {
      ...SessionCard
    }
  }
}
    ${SessionCardFragmentDoc}`;
export type UnSignUpFromSessionMutationFn = Apollo.MutationFunction<Types.UnSignUpFromSessionMutation, Types.UnSignUpFromSessionMutationVariables>;

/**
 * __useUnSignUpFromSessionMutation__
 *
 * To run a mutation, you first call `useUnSignUpFromSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnSignUpFromSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unSignUpFromSessionMutation, { data, loading, error }] = useUnSignUpFromSessionMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useUnSignUpFromSessionMutation(baseOptions?: Apollo.MutationHookOptions<Types.UnSignUpFromSessionMutation, Types.UnSignUpFromSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UnSignUpFromSessionMutation, Types.UnSignUpFromSessionMutationVariables>(UnSignUpFromSessionDocument, options);
      }
export type UnSignUpFromSessionMutationHookResult = ReturnType<typeof useUnSignUpFromSessionMutation>;
export type UnSignUpFromSessionMutationResult = Apollo.MutationResult<Types.UnSignUpFromSessionMutation>;
export type UnSignUpFromSessionMutationOptions = Apollo.BaseMutationOptions<Types.UnSignUpFromSessionMutation, Types.UnSignUpFromSessionMutationVariables>;
export const UnblockDocument = gql`
    mutation Unblock($runnerIds: [ID!]!) {
  response: unblock(runnerIds: $runnerIds) {
    runners {
      ...FollowStatus
    }
  }
}
    ${FollowStatusFragmentDoc}`;
export type UnblockMutationFn = Apollo.MutationFunction<Types.UnblockMutation, Types.UnblockMutationVariables>;

/**
 * __useUnblockMutation__
 *
 * To run a mutation, you first call `useUnblockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnblockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unblockMutation, { data, loading, error }] = useUnblockMutation({
 *   variables: {
 *      runnerIds: // value for 'runnerIds'
 *   },
 * });
 */
export function useUnblockMutation(baseOptions?: Apollo.MutationHookOptions<Types.UnblockMutation, Types.UnblockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UnblockMutation, Types.UnblockMutationVariables>(UnblockDocument, options);
      }
export type UnblockMutationHookResult = ReturnType<typeof useUnblockMutation>;
export type UnblockMutationResult = Apollo.MutationResult<Types.UnblockMutation>;
export type UnblockMutationOptions = Apollo.BaseMutationOptions<Types.UnblockMutation, Types.UnblockMutationVariables>;
export const UncheerDocument = gql`
    mutation Uncheer($cheerableActivityId: ID!, $cheerableType: CheerableType!, $runnerIds: [ID!]!) {
  response: uncheer(
    cheerableActivityId: $cheerableActivityId
    cheerableType: $cheerableType
    runnerIds: $runnerIds
  ) {
    cheerable {
      ...Cheerable
    }
  }
}
    ${CheerableFragmentDoc}`;
export type UncheerMutationFn = Apollo.MutationFunction<Types.UncheerMutation, Types.UncheerMutationVariables>;

/**
 * __useUncheerMutation__
 *
 * To run a mutation, you first call `useUncheerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUncheerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uncheerMutation, { data, loading, error }] = useUncheerMutation({
 *   variables: {
 *      cheerableActivityId: // value for 'cheerableActivityId'
 *      cheerableType: // value for 'cheerableType'
 *      runnerIds: // value for 'runnerIds'
 *   },
 * });
 */
export function useUncheerMutation(baseOptions?: Apollo.MutationHookOptions<Types.UncheerMutation, Types.UncheerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UncheerMutation, Types.UncheerMutationVariables>(UncheerDocument, options);
      }
export type UncheerMutationHookResult = ReturnType<typeof useUncheerMutation>;
export type UncheerMutationResult = Apollo.MutationResult<Types.UncheerMutation>;
export type UncheerMutationOptions = Apollo.BaseMutationOptions<Types.UncheerMutation, Types.UncheerMutationVariables>;
export const UndeclineTaskRequestDocument = gql`
    mutation UndeclineTaskRequest($taskRequestId: ID!) {
  response: undeclineTaskRequest(taskRequestId: $taskRequestId) {
    taskRequest {
      ...TaskRequestCard
    }
  }
}
    ${TaskRequestCardFragmentDoc}`;
export type UndeclineTaskRequestMutationFn = Apollo.MutationFunction<Types.UndeclineTaskRequestMutation, Types.UndeclineTaskRequestMutationVariables>;

/**
 * __useUndeclineTaskRequestMutation__
 *
 * To run a mutation, you first call `useUndeclineTaskRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUndeclineTaskRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [undeclineTaskRequestMutation, { data, loading, error }] = useUndeclineTaskRequestMutation({
 *   variables: {
 *      taskRequestId: // value for 'taskRequestId'
 *   },
 * });
 */
export function useUndeclineTaskRequestMutation(baseOptions?: Apollo.MutationHookOptions<Types.UndeclineTaskRequestMutation, Types.UndeclineTaskRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UndeclineTaskRequestMutation, Types.UndeclineTaskRequestMutationVariables>(UndeclineTaskRequestDocument, options);
      }
export type UndeclineTaskRequestMutationHookResult = ReturnType<typeof useUndeclineTaskRequestMutation>;
export type UndeclineTaskRequestMutationResult = Apollo.MutationResult<Types.UndeclineTaskRequestMutation>;
export type UndeclineTaskRequestMutationOptions = Apollo.BaseMutationOptions<Types.UndeclineTaskRequestMutation, Types.UndeclineTaskRequestMutationVariables>;
export const UnfollowDocument = gql`
    mutation Unfollow($runnerIds: [ID!]!) {
  response: unfollow(runnerIds: $runnerIds) {
    runners {
      ...FollowStatus
    }
  }
}
    ${FollowStatusFragmentDoc}`;
export type UnfollowMutationFn = Apollo.MutationFunction<Types.UnfollowMutation, Types.UnfollowMutationVariables>;

/**
 * __useUnfollowMutation__
 *
 * To run a mutation, you first call `useUnfollowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnfollowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unfollowMutation, { data, loading, error }] = useUnfollowMutation({
 *   variables: {
 *      runnerIds: // value for 'runnerIds'
 *   },
 * });
 */
export function useUnfollowMutation(baseOptions?: Apollo.MutationHookOptions<Types.UnfollowMutation, Types.UnfollowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UnfollowMutation, Types.UnfollowMutationVariables>(UnfollowDocument, options);
      }
export type UnfollowMutationHookResult = ReturnType<typeof useUnfollowMutation>;
export type UnfollowMutationResult = Apollo.MutationResult<Types.UnfollowMutation>;
export type UnfollowMutationOptions = Apollo.BaseMutationOptions<Types.UnfollowMutation, Types.UnfollowMutationVariables>;
export const UnpublishReportDocument = gql`
    mutation UnpublishReport($reportId: ID!) {
  response: unpublishReport(reportId: $reportId) {
    report {
      ...Report
    }
  }
}
    ${ReportFragmentDoc}`;
export type UnpublishReportMutationFn = Apollo.MutationFunction<Types.UnpublishReportMutation, Types.UnpublishReportMutationVariables>;

/**
 * __useUnpublishReportMutation__
 *
 * To run a mutation, you first call `useUnpublishReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpublishReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpublishReportMutation, { data, loading, error }] = useUnpublishReportMutation({
 *   variables: {
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useUnpublishReportMutation(baseOptions?: Apollo.MutationHookOptions<Types.UnpublishReportMutation, Types.UnpublishReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UnpublishReportMutation, Types.UnpublishReportMutationVariables>(UnpublishReportDocument, options);
      }
export type UnpublishReportMutationHookResult = ReturnType<typeof useUnpublishReportMutation>;
export type UnpublishReportMutationResult = Apollo.MutationResult<Types.UnpublishReportMutation>;
export type UnpublishReportMutationOptions = Apollo.BaseMutationOptions<Types.UnpublishReportMutation, Types.UnpublishReportMutationVariables>;
export const UpdateAreaDocument = gql`
    mutation UpdateArea($areaId: ID!, $description: String) {
  response: updateArea(areaId: $areaId, description: $description) {
    area {
      ...Area
      description
    }
  }
}
    ${AreaFragmentDoc}`;
export type UpdateAreaMutationFn = Apollo.MutationFunction<Types.UpdateAreaMutation, Types.UpdateAreaMutationVariables>;

/**
 * __useUpdateAreaMutation__
 *
 * To run a mutation, you first call `useUpdateAreaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAreaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAreaMutation, { data, loading, error }] = useUpdateAreaMutation({
 *   variables: {
 *      areaId: // value for 'areaId'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateAreaMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateAreaMutation, Types.UpdateAreaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateAreaMutation, Types.UpdateAreaMutationVariables>(UpdateAreaDocument, options);
      }
export type UpdateAreaMutationHookResult = ReturnType<typeof useUpdateAreaMutation>;
export type UpdateAreaMutationResult = Apollo.MutationResult<Types.UpdateAreaMutation>;
export type UpdateAreaMutationOptions = Apollo.BaseMutationOptions<Types.UpdateAreaMutation, Types.UpdateAreaMutationVariables>;
export const UpdateOrganisationDocument = gql`
    mutation UpdateOrganisation($organisationId: ID!, $description: String) {
  response: updateOrganisation(
    organisationId: $organisationId
    description: $description
  ) {
    organisation {
      ...Organisation
      description
    }
  }
}
    ${OrganisationFragmentDoc}`;
export type UpdateOrganisationMutationFn = Apollo.MutationFunction<Types.UpdateOrganisationMutation, Types.UpdateOrganisationMutationVariables>;

/**
 * __useUpdateOrganisationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganisationMutation, { data, loading, error }] = useUpdateOrganisationMutation({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateOrganisationMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateOrganisationMutation, Types.UpdateOrganisationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateOrganisationMutation, Types.UpdateOrganisationMutationVariables>(UpdateOrganisationDocument, options);
      }
export type UpdateOrganisationMutationHookResult = ReturnType<typeof useUpdateOrganisationMutation>;
export type UpdateOrganisationMutationResult = Apollo.MutationResult<Types.UpdateOrganisationMutation>;
export type UpdateOrganisationMutationOptions = Apollo.BaseMutationOptions<Types.UpdateOrganisationMutation, Types.UpdateOrganisationMutationVariables>;
export const AreaPageSessionsDocument = gql`
    query AreaPageSessions($types: [String!]!, $areaIds: [ID!]!, $from: ISO8601DateTime!, $sessionsCursorDate: ISO8601DateTime, $sessionsCursorId: ID, $taskRequestsCursorDate: ISO8601DateTime, $taskRequestsCursorId: ID, $limit: Int) {
  sessions: sessionsV2(
    types: $types
    areaIds: $areaIds
    from: $from
    cursorDate: $sessionsCursorDate
    cursorId: $sessionsCursorId
    limit: $limit
  ) {
    more
    results {
      ...SessionCard
    }
  }
  taskRequests(
    types: $types
    areaIds: $areaIds
    from: $from
    cursorDate: $taskRequestsCursorDate
    cursorId: $taskRequestsCursorId
    limit: $limit
  ) {
    more
    results {
      ...TaskRequestCard
    }
  }
}
    ${SessionCardFragmentDoc}
${TaskRequestCardFragmentDoc}`;

/**
 * __useAreaPageSessionsQuery__
 *
 * To run a query within a React component, call `useAreaPageSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAreaPageSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAreaPageSessionsQuery({
 *   variables: {
 *      types: // value for 'types'
 *      areaIds: // value for 'areaIds'
 *      from: // value for 'from'
 *      sessionsCursorDate: // value for 'sessionsCursorDate'
 *      sessionsCursorId: // value for 'sessionsCursorId'
 *      taskRequestsCursorDate: // value for 'taskRequestsCursorDate'
 *      taskRequestsCursorId: // value for 'taskRequestsCursorId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useAreaPageSessionsQuery(baseOptions: Apollo.QueryHookOptions<Types.AreaPageSessionsQuery, Types.AreaPageSessionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AreaPageSessionsQuery, Types.AreaPageSessionsQueryVariables>(AreaPageSessionsDocument, options);
      }
export function useAreaPageSessionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AreaPageSessionsQuery, Types.AreaPageSessionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AreaPageSessionsQuery, Types.AreaPageSessionsQueryVariables>(AreaPageSessionsDocument, options);
        }
export type AreaPageSessionsQueryHookResult = ReturnType<typeof useAreaPageSessionsQuery>;
export type AreaPageSessionsLazyQueryHookResult = ReturnType<typeof useAreaPageSessionsLazyQuery>;
export type AreaPageSessionsQueryResult = Apollo.QueryResult<Types.AreaPageSessionsQuery, Types.AreaPageSessionsQueryVariables>;
export const AreaPageDocument = gql`
    query AreaPage($slug: String!) {
  area(slug: $slug) {
    ...Area
    description
    active
    imageUrl
    areaActivator {
      ...Runner
      bio
    }
    taskforce {
      ...Runner
    }
    runLeaders {
      ...Runner
    }
    partners {
      id
      name
      description
      imageUrl
      url
    }
    email
    twitter
    facebook
    instagram
  }
}
    ${AreaFragmentDoc}
${RunnerFragmentDoc}`;

/**
 * __useAreaPageQuery__
 *
 * To run a query within a React component, call `useAreaPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAreaPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAreaPageQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useAreaPageQuery(baseOptions: Apollo.QueryHookOptions<Types.AreaPageQuery, Types.AreaPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AreaPageQuery, Types.AreaPageQueryVariables>(AreaPageDocument, options);
      }
export function useAreaPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AreaPageQuery, Types.AreaPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AreaPageQuery, Types.AreaPageQueryVariables>(AreaPageDocument, options);
        }
export type AreaPageQueryHookResult = ReturnType<typeof useAreaPageQuery>;
export type AreaPageLazyQueryHookResult = ReturnType<typeof useAreaPageLazyQuery>;
export type AreaPageQueryResult = Apollo.QueryResult<Types.AreaPageQuery, Types.AreaPageQueryVariables>;
export const ContactPageDocument = gql`
    query ContactPage($slug: String!) {
  area(slug: $slug) {
    ...Area
    areaActivator {
      ...Runner
      bio
    }
    email
    twitter
    facebook
    instagram
  }
}
    ${AreaFragmentDoc}
${RunnerFragmentDoc}`;

/**
 * __useContactPageQuery__
 *
 * To run a query within a React component, call `useContactPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactPageQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useContactPageQuery(baseOptions: Apollo.QueryHookOptions<Types.ContactPageQuery, Types.ContactPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ContactPageQuery, Types.ContactPageQueryVariables>(ContactPageDocument, options);
      }
export function useContactPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ContactPageQuery, Types.ContactPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ContactPageQuery, Types.ContactPageQueryVariables>(ContactPageDocument, options);
        }
export type ContactPageQueryHookResult = ReturnType<typeof useContactPageQuery>;
export type ContactPageLazyQueryHookResult = ReturnType<typeof useContactPageLazyQuery>;
export type ContactPageQueryResult = Apollo.QueryResult<Types.ContactPageQuery, Types.ContactPageQueryVariables>;
export const DashboardPageDocument = gql`
    query DashboardPage {
  runner: currentRunner {
    ...CurrentRunner
    upcomingSessions {
      ...SessionCard
    }
    suggestedSessions {
      ...SessionCard
    }
    roles
    stats {
      ...RunnerStats
    }
    ctas {
      ...CTA
    }
  }
}
    ${CurrentRunnerFragmentDoc}
${SessionCardFragmentDoc}
${RunnerStatsFragmentDoc}
${CtaFragmentDoc}`;

/**
 * __useDashboardPageQuery__
 *
 * To run a query within a React component, call `useDashboardPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardPageQuery(baseOptions?: Apollo.QueryHookOptions<Types.DashboardPageQuery, Types.DashboardPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DashboardPageQuery, Types.DashboardPageQueryVariables>(DashboardPageDocument, options);
      }
export function useDashboardPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DashboardPageQuery, Types.DashboardPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DashboardPageQuery, Types.DashboardPageQueryVariables>(DashboardPageDocument, options);
        }
export type DashboardPageQueryHookResult = ReturnType<typeof useDashboardPageQuery>;
export type DashboardPageLazyQueryHookResult = ReturnType<typeof useDashboardPageLazyQuery>;
export type DashboardPageQueryResult = Apollo.QueryResult<Types.DashboardPageQuery, Types.DashboardPageQueryVariables>;
export const DeletedStoryPageDocument = gql`
    query DeletedStoryPage($before: ISO8601DateTime) {
  deletedStories(before: $before) {
    stories {
      ...Story
      discardedAt
    }
    more
  }
}
    ${StoryFragmentDoc}`;

/**
 * __useDeletedStoryPageQuery__
 *
 * To run a query within a React component, call `useDeletedStoryPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeletedStoryPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeletedStoryPageQuery({
 *   variables: {
 *      before: // value for 'before'
 *   },
 * });
 */
export function useDeletedStoryPageQuery(baseOptions?: Apollo.QueryHookOptions<Types.DeletedStoryPageQuery, Types.DeletedStoryPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DeletedStoryPageQuery, Types.DeletedStoryPageQueryVariables>(DeletedStoryPageDocument, options);
      }
export function useDeletedStoryPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DeletedStoryPageQuery, Types.DeletedStoryPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DeletedStoryPageQuery, Types.DeletedStoryPageQueryVariables>(DeletedStoryPageDocument, options);
        }
export type DeletedStoryPageQueryHookResult = ReturnType<typeof useDeletedStoryPageQuery>;
export type DeletedStoryPageLazyQueryHookResult = ReturnType<typeof useDeletedStoryPageLazyQuery>;
export type DeletedStoryPageQueryResult = Apollo.QueryResult<Types.DeletedStoryPageQuery, Types.DeletedStoryPageQueryVariables>;
export const DraftedStoryPageDocument = gql`
    query DraftedStoryPage($before: ISO8601DateTime) {
  draftedStories(before: $before) {
    stories {
      ...Story
      discardedAt
    }
    more
  }
}
    ${StoryFragmentDoc}`;

/**
 * __useDraftedStoryPageQuery__
 *
 * To run a query within a React component, call `useDraftedStoryPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftedStoryPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftedStoryPageQuery({
 *   variables: {
 *      before: // value for 'before'
 *   },
 * });
 */
export function useDraftedStoryPageQuery(baseOptions?: Apollo.QueryHookOptions<Types.DraftedStoryPageQuery, Types.DraftedStoryPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.DraftedStoryPageQuery, Types.DraftedStoryPageQueryVariables>(DraftedStoryPageDocument, options);
      }
export function useDraftedStoryPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.DraftedStoryPageQuery, Types.DraftedStoryPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.DraftedStoryPageQuery, Types.DraftedStoryPageQueryVariables>(DraftedStoryPageDocument, options);
        }
export type DraftedStoryPageQueryHookResult = ReturnType<typeof useDraftedStoryPageQuery>;
export type DraftedStoryPageLazyQueryHookResult = ReturnType<typeof useDraftedStoryPageLazyQuery>;
export type DraftedStoryPageQueryResult = Apollo.QueryResult<Types.DraftedStoryPageQuery, Types.DraftedStoryPageQueryVariables>;
export const HomePageDocument = gql`
    query HomePage {
  stats {
    goodgymers
    areas
    tasks
    beneficiaries
  }
}
    `;

/**
 * __useHomePageQuery__
 *
 * To run a query within a React component, call `useHomePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomePageQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomePageQuery(baseOptions?: Apollo.QueryHookOptions<Types.HomePageQuery, Types.HomePageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.HomePageQuery, Types.HomePageQueryVariables>(HomePageDocument, options);
      }
export function useHomePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.HomePageQuery, Types.HomePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.HomePageQuery, Types.HomePageQueryVariables>(HomePageDocument, options);
        }
export type HomePageQueryHookResult = ReturnType<typeof useHomePageQuery>;
export type HomePageLazyQueryHookResult = ReturnType<typeof useHomePageLazyQuery>;
export type HomePageQueryResult = Apollo.QueryResult<Types.HomePageQuery, Types.HomePageQueryVariables>;
export const MyTeamsPageDocument = gql`
    query MyTeamsPage {
  team {
    supporters {
      id
      name
      slug
      avatarUrl
      followsYou
      blockedByYou
      followedByYou
      isMember
      neverRun
      verified
      pronouns
      roles
    }
    suggestions {
      id
      name
      slug
      avatarUrl
      followsYou
      blockedByYou
      followedByYou
      isMember
      neverRun
      verified
      pronouns
      roles
    }
    supportings {
      id
      name
      slug
      avatarUrl
      followsYou
      blockedByYou
      followedByYou
      isMember
      neverRun
      verified
      pronouns
      roles
    }
  }
}
    `;

/**
 * __useMyTeamsPageQuery__
 *
 * To run a query within a React component, call `useMyTeamsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyTeamsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyTeamsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyTeamsPageQuery(baseOptions?: Apollo.QueryHookOptions<Types.MyTeamsPageQuery, Types.MyTeamsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.MyTeamsPageQuery, Types.MyTeamsPageQueryVariables>(MyTeamsPageDocument, options);
      }
export function useMyTeamsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.MyTeamsPageQuery, Types.MyTeamsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.MyTeamsPageQuery, Types.MyTeamsPageQueryVariables>(MyTeamsPageDocument, options);
        }
export type MyTeamsPageQueryHookResult = ReturnType<typeof useMyTeamsPageQuery>;
export type MyTeamsPageLazyQueryHookResult = ReturnType<typeof useMyTeamsPageLazyQuery>;
export type MyTeamsPageQueryResult = Apollo.QueryResult<Types.MyTeamsPageQuery, Types.MyTeamsPageQueryVariables>;
export const OpsSupportPageDocument = gql`
    query OpsSupportPage {
  ping
}
    `;

/**
 * __useOpsSupportPageQuery__
 *
 * To run a query within a React component, call `useOpsSupportPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpsSupportPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpsSupportPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useOpsSupportPageQuery(baseOptions?: Apollo.QueryHookOptions<Types.OpsSupportPageQuery, Types.OpsSupportPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OpsSupportPageQuery, Types.OpsSupportPageQueryVariables>(OpsSupportPageDocument, options);
      }
export function useOpsSupportPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OpsSupportPageQuery, Types.OpsSupportPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OpsSupportPageQuery, Types.OpsSupportPageQueryVariables>(OpsSupportPageDocument, options);
        }
export type OpsSupportPageQueryHookResult = ReturnType<typeof useOpsSupportPageQuery>;
export type OpsSupportPageLazyQueryHookResult = ReturnType<typeof useOpsSupportPageLazyQuery>;
export type OpsSupportPageQueryResult = Apollo.QueryResult<Types.OpsSupportPageQuery, Types.OpsSupportPageQueryVariables>;
export const OrganisationPageDocument = gql`
    query OrganisationPage($slug: String!) {
  organisation(slug: $slug) {
    ...Organisation
    area {
      ...Area
    }
    description
    url
    logoUrl
    imageUrl
    totalSessions
    totalRunners
    topSupporters {
      ...Runner
    }
    upcomingSessions {
      ...Session
    }
  }
}
    ${OrganisationFragmentDoc}
${AreaFragmentDoc}
${RunnerFragmentDoc}
${SessionFragmentDoc}`;

/**
 * __useOrganisationPageQuery__
 *
 * To run a query within a React component, call `useOrganisationPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationPageQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useOrganisationPageQuery(baseOptions: Apollo.QueryHookOptions<Types.OrganisationPageQuery, Types.OrganisationPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.OrganisationPageQuery, Types.OrganisationPageQueryVariables>(OrganisationPageDocument, options);
      }
export function useOrganisationPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.OrganisationPageQuery, Types.OrganisationPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.OrganisationPageQuery, Types.OrganisationPageQueryVariables>(OrganisationPageDocument, options);
        }
export type OrganisationPageQueryHookResult = ReturnType<typeof useOrganisationPageQuery>;
export type OrganisationPageLazyQueryHookResult = ReturnType<typeof useOrganisationPageLazyQuery>;
export type OrganisationPageQueryResult = Apollo.QueryResult<Types.OrganisationPageQuery, Types.OrganisationPageQueryVariables>;
export const ProfilePageDocument = gql`
    query ProfilePage($slug: ID!, $currentRunnerPresent: Boolean!) {
  runner(id: $slug) {
    ...Runner
    ...FollowStatus
    homeArea {
      ...Area
    }
    awards {
      id
      badge {
        ...Badge
      }
    }
    stats {
      ...RunnerStats
      ...TaskForceStats
    }
    streaks {
      ...RunnerStreak
    }
    employment {
      ...Employment
    }
    upcomingSessions(limit: 1) {
      ...SessionCard
    }
    joinedAt
    bio
    twitter
    instagram
    isMember
    neverRun
    verified
    roles
    user @include(if: $currentRunnerPresent) {
      email
    }
  }
}
    ${RunnerFragmentDoc}
${FollowStatusFragmentDoc}
${AreaFragmentDoc}
${BadgeFragmentDoc}
${RunnerStatsFragmentDoc}
${TaskForceStatsFragmentDoc}
${RunnerStreakFragmentDoc}
${EmploymentFragmentDoc}
${SessionCardFragmentDoc}`;

/**
 * __useProfilePageQuery__
 *
 * To run a query within a React component, call `useProfilePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfilePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfilePageQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      currentRunnerPresent: // value for 'currentRunnerPresent'
 *   },
 * });
 */
export function useProfilePageQuery(baseOptions: Apollo.QueryHookOptions<Types.ProfilePageQuery, Types.ProfilePageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ProfilePageQuery, Types.ProfilePageQueryVariables>(ProfilePageDocument, options);
      }
export function useProfilePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ProfilePageQuery, Types.ProfilePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ProfilePageQuery, Types.ProfilePageQueryVariables>(ProfilePageDocument, options);
        }
export type ProfilePageQueryHookResult = ReturnType<typeof useProfilePageQuery>;
export type ProfilePageLazyQueryHookResult = ReturnType<typeof useProfilePageLazyQuery>;
export type ProfilePageQueryResult = Apollo.QueryResult<Types.ProfilePageQuery, Types.ProfilePageQueryVariables>;
export const ReportPageDocument = gql`
    query ReportPage($slug: String!) {
  report(slug: $slug) {
    ...Report
    title
    description
    slug
    imageUrl
    writtenBy {
      ...Runner
    }
    session {
      ...Session
      organisation {
        ...Organisation
        url
        logoUrl
        description
        area {
          ...Area
        }
      }
      signedUp
      signups {
        ...Runner
      }
      ledBy {
        ...Runner
        ...RunnerStatus
        ...FollowStatus
        bio
        roles
        homeArea {
          ...Area
        }
      }
      area {
        ...Area
        upcomingSessions(limit: 1) {
          ...Session
          signedUp
          signups {
            ...Runner
          }
          area {
            ...Area
          }
        }
      }
      attendees {
        ...Runner
      }
    }
    comments {
      ...Comment
    }
  }
}
    ${ReportFragmentDoc}
${RunnerFragmentDoc}
${SessionFragmentDoc}
${OrganisationFragmentDoc}
${AreaFragmentDoc}
${RunnerStatusFragmentDoc}
${FollowStatusFragmentDoc}
${CommentFragmentDoc}`;

/**
 * __useReportPageQuery__
 *
 * To run a query within a React component, call `useReportPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportPageQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useReportPageQuery(baseOptions: Apollo.QueryHookOptions<Types.ReportPageQuery, Types.ReportPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ReportPageQuery, Types.ReportPageQueryVariables>(ReportPageDocument, options);
      }
export function useReportPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ReportPageQuery, Types.ReportPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ReportPageQuery, Types.ReportPageQueryVariables>(ReportPageDocument, options);
        }
export type ReportPageQueryHookResult = ReturnType<typeof useReportPageQuery>;
export type ReportPageLazyQueryHookResult = ReturnType<typeof useReportPageLazyQuery>;
export type ReportPageQueryResult = Apollo.QueryResult<Types.ReportPageQuery, Types.ReportPageQueryVariables>;
export const ReportsPageDocument = gql`
    query ReportsPage {
  ping
}
    `;

/**
 * __useReportsPageQuery__
 *
 * To run a query within a React component, call `useReportsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useReportsPageQuery(baseOptions?: Apollo.QueryHookOptions<Types.ReportsPageQuery, Types.ReportsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.ReportsPageQuery, Types.ReportsPageQueryVariables>(ReportsPageDocument, options);
      }
export function useReportsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.ReportsPageQuery, Types.ReportsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.ReportsPageQuery, Types.ReportsPageQueryVariables>(ReportsPageDocument, options);
        }
export type ReportsPageQueryHookResult = ReturnType<typeof useReportsPageQuery>;
export type ReportsPageLazyQueryHookResult = ReturnType<typeof useReportsPageLazyQuery>;
export type ReportsPageQueryResult = Apollo.QueryResult<Types.ReportsPageQuery, Types.ReportsPageQueryVariables>;
export const SessionPageAdminMenuDocument = gql`
    query SessionPageAdminMenu($sessionId: ID!) {
  options: sessionAdminMenu(sessionId: $sessionId) {
    ...MenuOption
  }
}
    ${MenuOptionFragmentDoc}`;

/**
 * __useSessionPageAdminMenuQuery__
 *
 * To run a query within a React component, call `useSessionPageAdminMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionPageAdminMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionPageAdminMenuQuery({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useSessionPageAdminMenuQuery(baseOptions: Apollo.QueryHookOptions<Types.SessionPageAdminMenuQuery, Types.SessionPageAdminMenuQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SessionPageAdminMenuQuery, Types.SessionPageAdminMenuQueryVariables>(SessionPageAdminMenuDocument, options);
      }
export function useSessionPageAdminMenuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SessionPageAdminMenuQuery, Types.SessionPageAdminMenuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SessionPageAdminMenuQuery, Types.SessionPageAdminMenuQueryVariables>(SessionPageAdminMenuDocument, options);
        }
export type SessionPageAdminMenuQueryHookResult = ReturnType<typeof useSessionPageAdminMenuQuery>;
export type SessionPageAdminMenuLazyQueryHookResult = ReturnType<typeof useSessionPageAdminMenuLazyQuery>;
export type SessionPageAdminMenuQueryResult = Apollo.QueryResult<Types.SessionPageAdminMenuQuery, Types.SessionPageAdminMenuQueryVariables>;
export const SessionPageDocument = gql`
    query SessionPage($slug: String!) {
  session(slug: $slug) {
    ...Session
    ...Commentable
    imageUrls(size: xlarge)
    cancelledAt
    registerDeadline
    description
    signedUp
    onWaitingList
    walkable
    minDistance
    maxDistance
    report {
      ...Report
    }
    organisation {
      ...Organisation
      description
      logoUrl
    }
    risks {
      ...SessionRisk
    }
    address {
      ...Address
      accessNotes
      otherInformation
    }
    signups {
      ...Runner
      ...RunnerStatus
    }
    ledBy {
      ...Runner
      ...RunnerStatus
      bio
    }
    area {
      ...Area
      email
      areaActivator {
        ...Runner
      }
    }
    groupFitnessActivities {
      ...GroupFitnessActivity
      ledBy {
        ...Runner
        ...RunnerStatus
      }
      signups {
        ...Runner
      }
    }
    taskforceRoles {
      ...TaskforceRole
    }
    taskRequest {
      ...TaskRequest
      risky
      beneficiary {
        ...Beneficiary
      }
    }
  }
}
    ${SessionFragmentDoc}
${CommentableFragmentDoc}
${ReportFragmentDoc}
${OrganisationFragmentDoc}
${SessionRiskFragmentDoc}
${AddressFragmentDoc}
${RunnerFragmentDoc}
${RunnerStatusFragmentDoc}
${AreaFragmentDoc}
${GroupFitnessActivityFragmentDoc}
${TaskforceRoleFragmentDoc}
${TaskRequestFragmentDoc}
${BeneficiaryFragmentDoc}`;

/**
 * __useSessionPageQuery__
 *
 * To run a query within a React component, call `useSessionPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionPageQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useSessionPageQuery(baseOptions: Apollo.QueryHookOptions<Types.SessionPageQuery, Types.SessionPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.SessionPageQuery, Types.SessionPageQueryVariables>(SessionPageDocument, options);
      }
export function useSessionPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.SessionPageQuery, Types.SessionPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.SessionPageQuery, Types.SessionPageQueryVariables>(SessionPageDocument, options);
        }
export type SessionPageQueryHookResult = ReturnType<typeof useSessionPageQuery>;
export type SessionPageLazyQueryHookResult = ReturnType<typeof useSessionPageLazyQuery>;
export type SessionPageQueryResult = Apollo.QueryResult<Types.SessionPageQuery, Types.SessionPageQueryVariables>;
export const AvailableCoachPageDocument = gql`
    query AvailableCoachPage($id: ID!) {
  availableCoach(id: $id) {
    ...CoachReferral
  }
}
    ${CoachReferralFragmentDoc}`;

/**
 * __useAvailableCoachPageQuery__
 *
 * To run a query within a React component, call `useAvailableCoachPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableCoachPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableCoachPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAvailableCoachPageQuery(baseOptions: Apollo.QueryHookOptions<Types.AvailableCoachPageQuery, Types.AvailableCoachPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AvailableCoachPageQuery, Types.AvailableCoachPageQueryVariables>(AvailableCoachPageDocument, options);
      }
export function useAvailableCoachPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AvailableCoachPageQuery, Types.AvailableCoachPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AvailableCoachPageQuery, Types.AvailableCoachPageQueryVariables>(AvailableCoachPageDocument, options);
        }
export type AvailableCoachPageQueryHookResult = ReturnType<typeof useAvailableCoachPageQuery>;
export type AvailableCoachPageLazyQueryHookResult = ReturnType<typeof useAvailableCoachPageLazyQuery>;
export type AvailableCoachPageQueryResult = Apollo.QueryResult<Types.AvailableCoachPageQuery, Types.AvailableCoachPageQueryVariables>;
export const TestPairingsDocument = gql`
    query TestPairings($page: Int, $limit: Int) {
  testPairings(page: $page, limit: $limit) {
    more
    results {
      ...TestPairing
    }
  }
}
    ${TestPairingFragmentDoc}`;

/**
 * __useTestPairingsQuery__
 *
 * To run a query within a React component, call `useTestPairingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestPairingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestPairingsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useTestPairingsQuery(baseOptions?: Apollo.QueryHookOptions<Types.TestPairingsQuery, Types.TestPairingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TestPairingsQuery, Types.TestPairingsQueryVariables>(TestPairingsDocument, options);
      }
export function useTestPairingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TestPairingsQuery, Types.TestPairingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TestPairingsQuery, Types.TestPairingsQueryVariables>(TestPairingsDocument, options);
        }
export type TestPairingsQueryHookResult = ReturnType<typeof useTestPairingsQuery>;
export type TestPairingsLazyQueryHookResult = ReturnType<typeof useTestPairingsLazyQuery>;
export type TestPairingsQueryResult = Apollo.QueryResult<Types.TestPairingsQuery, Types.TestPairingsQueryVariables>;
export const TestPairingDocument = gql`
    query TestPairing($id: ID!) {
  testPairing(id: $id) {
    ...TestPairing
  }
}
    ${TestPairingFragmentDoc}`;

/**
 * __useTestPairingQuery__
 *
 * To run a query within a React component, call `useTestPairingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestPairingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestPairingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTestPairingQuery(baseOptions: Apollo.QueryHookOptions<Types.TestPairingQuery, Types.TestPairingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TestPairingQuery, Types.TestPairingQueryVariables>(TestPairingDocument, options);
      }
export function useTestPairingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TestPairingQuery, Types.TestPairingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TestPairingQuery, Types.TestPairingQueryVariables>(TestPairingDocument, options);
        }
export type TestPairingQueryHookResult = ReturnType<typeof useTestPairingQuery>;
export type TestPairingLazyQueryHookResult = ReturnType<typeof useTestPairingLazyQuery>;
export type TestPairingQueryResult = Apollo.QueryResult<Types.TestPairingQuery, Types.TestPairingQueryVariables>;
export const AvailableCoachesDocument = gql`
    query AvailableCoaches($areaIds: [ID!], $postcode: String, $maxDistance: Int, $page: Int, $limit: Int) {
  availableCoaches(
    areaIds: $areaIds
    postcode: $postcode
    maxDistance: $maxDistance
    page: $page
    limit: $limit
  ) {
    more
    results {
      ...CoachReferral
    }
  }
}
    ${CoachReferralFragmentDoc}`;

/**
 * __useAvailableCoachesQuery__
 *
 * To run a query within a React component, call `useAvailableCoachesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableCoachesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableCoachesQuery({
 *   variables: {
 *      areaIds: // value for 'areaIds'
 *      postcode: // value for 'postcode'
 *      maxDistance: // value for 'maxDistance'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useAvailableCoachesQuery(baseOptions?: Apollo.QueryHookOptions<Types.AvailableCoachesQuery, Types.AvailableCoachesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AvailableCoachesQuery, Types.AvailableCoachesQueryVariables>(AvailableCoachesDocument, options);
      }
export function useAvailableCoachesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AvailableCoachesQuery, Types.AvailableCoachesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AvailableCoachesQuery, Types.AvailableCoachesQueryVariables>(AvailableCoachesDocument, options);
        }
export type AvailableCoachesQueryHookResult = ReturnType<typeof useAvailableCoachesQuery>;
export type AvailableCoachesLazyQueryHookResult = ReturnType<typeof useAvailableCoachesLazyQuery>;
export type AvailableCoachesQueryResult = Apollo.QueryResult<Types.AvailableCoachesQuery, Types.AvailableCoachesQueryVariables>;
export const AvailableCoachDocument = gql`
    query AvailableCoach($id: ID!) {
  availableCoach(id: $id) {
    ...CoachReferral
  }
}
    ${CoachReferralFragmentDoc}`;

/**
 * __useAvailableCoachQuery__
 *
 * To run a query within a React component, call `useAvailableCoachQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableCoachQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableCoachQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAvailableCoachQuery(baseOptions: Apollo.QueryHookOptions<Types.AvailableCoachQuery, Types.AvailableCoachQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.AvailableCoachQuery, Types.AvailableCoachQueryVariables>(AvailableCoachDocument, options);
      }
export function useAvailableCoachLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.AvailableCoachQuery, Types.AvailableCoachQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.AvailableCoachQuery, Types.AvailableCoachQueryVariables>(AvailableCoachDocument, options);
        }
export type AvailableCoachQueryHookResult = ReturnType<typeof useAvailableCoachQuery>;
export type AvailableCoachLazyQueryHookResult = ReturnType<typeof useAvailableCoachLazyQuery>;
export type AvailableCoachQueryResult = Apollo.QueryResult<Types.AvailableCoachQuery, Types.AvailableCoachQueryVariables>;
export const StoriesPageDocument = gql`
    query StoriesPage($before: ISO8601DateTime) {
  stories(before: $before) {
    stories {
      ...Story
      discardedAt
      createdAt
    }
    more
  }
}
    ${StoryFragmentDoc}`;

/**
 * __useStoriesPageQuery__
 *
 * To run a query within a React component, call `useStoriesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoriesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoriesPageQuery({
 *   variables: {
 *      before: // value for 'before'
 *   },
 * });
 */
export function useStoriesPageQuery(baseOptions?: Apollo.QueryHookOptions<Types.StoriesPageQuery, Types.StoriesPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.StoriesPageQuery, Types.StoriesPageQueryVariables>(StoriesPageDocument, options);
      }
export function useStoriesPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.StoriesPageQuery, Types.StoriesPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.StoriesPageQuery, Types.StoriesPageQueryVariables>(StoriesPageDocument, options);
        }
export type StoriesPageQueryHookResult = ReturnType<typeof useStoriesPageQuery>;
export type StoriesPageLazyQueryHookResult = ReturnType<typeof useStoriesPageLazyQuery>;
export type StoriesPageQueryResult = Apollo.QueryResult<Types.StoriesPageQuery, Types.StoriesPageQueryVariables>;
export const DeleteStoryDocument = gql`
    mutation DeleteStory($storyId: ID!) {
  response: deleteStory(storyId: $storyId) {
    story {
      ...Story
    }
  }
}
    ${StoryFragmentDoc}`;
export type DeleteStoryMutationFn = Apollo.MutationFunction<Types.DeleteStoryMutation, Types.DeleteStoryMutationVariables>;

/**
 * __useDeleteStoryMutation__
 *
 * To run a mutation, you first call `useDeleteStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStoryMutation, { data, loading, error }] = useDeleteStoryMutation({
 *   variables: {
 *      storyId: // value for 'storyId'
 *   },
 * });
 */
export function useDeleteStoryMutation(baseOptions?: Apollo.MutationHookOptions<Types.DeleteStoryMutation, Types.DeleteStoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.DeleteStoryMutation, Types.DeleteStoryMutationVariables>(DeleteStoryDocument, options);
      }
export type DeleteStoryMutationHookResult = ReturnType<typeof useDeleteStoryMutation>;
export type DeleteStoryMutationResult = Apollo.MutationResult<Types.DeleteStoryMutation>;
export type DeleteStoryMutationOptions = Apollo.BaseMutationOptions<Types.DeleteStoryMutation, Types.DeleteStoryMutationVariables>;
export const PublishStoryDocument = gql`
    mutation PublishStory($storyId: ID!) {
  response: publishStory(storyId: $storyId) {
    story {
      ...Story
    }
  }
}
    ${StoryFragmentDoc}`;
export type PublishStoryMutationFn = Apollo.MutationFunction<Types.PublishStoryMutation, Types.PublishStoryMutationVariables>;

/**
 * __usePublishStoryMutation__
 *
 * To run a mutation, you first call `usePublishStoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishStoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishStoryMutation, { data, loading, error }] = usePublishStoryMutation({
 *   variables: {
 *      storyId: // value for 'storyId'
 *   },
 * });
 */
export function usePublishStoryMutation(baseOptions?: Apollo.MutationHookOptions<Types.PublishStoryMutation, Types.PublishStoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.PublishStoryMutation, Types.PublishStoryMutationVariables>(PublishStoryDocument, options);
      }
export type PublishStoryMutationHookResult = ReturnType<typeof usePublishStoryMutation>;
export type PublishStoryMutationResult = Apollo.MutationResult<Types.PublishStoryMutation>;
export type PublishStoryMutationOptions = Apollo.BaseMutationOptions<Types.PublishStoryMutation, Types.PublishStoryMutationVariables>;
export const StoryPageDocument = gql`
    query StoryPage($slug: String!) {
  story(slug: $slug) {
    ...Story
    discardedAt
  }
}
    ${StoryFragmentDoc}`;

/**
 * __useStoryPageQuery__
 *
 * To run a query within a React component, call `useStoryPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoryPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoryPageQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useStoryPageQuery(baseOptions: Apollo.QueryHookOptions<Types.StoryPageQuery, Types.StoryPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.StoryPageQuery, Types.StoryPageQueryVariables>(StoryPageDocument, options);
      }
export function useStoryPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.StoryPageQuery, Types.StoryPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.StoryPageQuery, Types.StoryPageQueryVariables>(StoryPageDocument, options);
        }
export type StoryPageQueryHookResult = ReturnType<typeof useStoryPageQuery>;
export type StoryPageLazyQueryHookResult = ReturnType<typeof useStoryPageLazyQuery>;
export type StoryPageQueryResult = Apollo.QueryResult<Types.StoryPageQuery, Types.StoryPageQueryVariables>;
export const CancelTestPairingDocument = gql`
    mutation CancelTestPairing($id: ID!, $cancellationNotes: String!) {
  response: cancelTestPairing(id: $id, cancellationNotes: $cancellationNotes) {
    testPairing {
      ...TestPairing
    }
  }
}
    ${TestPairingFragmentDoc}`;
export type CancelTestPairingMutationFn = Apollo.MutationFunction<Types.CancelTestPairingMutation, Types.CancelTestPairingMutationVariables>;

/**
 * __useCancelTestPairingMutation__
 *
 * To run a mutation, you first call `useCancelTestPairingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelTestPairingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelTestPairingMutation, { data, loading, error }] = useCancelTestPairingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      cancellationNotes: // value for 'cancellationNotes'
 *   },
 * });
 */
export function useCancelTestPairingMutation(baseOptions?: Apollo.MutationHookOptions<Types.CancelTestPairingMutation, Types.CancelTestPairingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CancelTestPairingMutation, Types.CancelTestPairingMutationVariables>(CancelTestPairingDocument, options);
      }
export type CancelTestPairingMutationHookResult = ReturnType<typeof useCancelTestPairingMutation>;
export type CancelTestPairingMutationResult = Apollo.MutationResult<Types.CancelTestPairingMutation>;
export type CancelTestPairingMutationOptions = Apollo.BaseMutationOptions<Types.CancelTestPairingMutation, Types.CancelTestPairingMutationVariables>;
export const RescheduleTestPairingDocument = gql`
    mutation RescheduleTestPairing($id: ID!) {
  response: cancelTestPairing(
    id: $id
    reschedule: true
    cancellationNotes: "Reschedule requested by Goodgymer"
  ) {
    testPairing {
      ...TestPairing
    }
  }
}
    ${TestPairingFragmentDoc}`;
export type RescheduleTestPairingMutationFn = Apollo.MutationFunction<Types.RescheduleTestPairingMutation, Types.RescheduleTestPairingMutationVariables>;

/**
 * __useRescheduleTestPairingMutation__
 *
 * To run a mutation, you first call `useRescheduleTestPairingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescheduleTestPairingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescheduleTestPairingMutation, { data, loading, error }] = useRescheduleTestPairingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRescheduleTestPairingMutation(baseOptions?: Apollo.MutationHookOptions<Types.RescheduleTestPairingMutation, Types.RescheduleTestPairingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.RescheduleTestPairingMutation, Types.RescheduleTestPairingMutationVariables>(RescheduleTestPairingDocument, options);
      }
export type RescheduleTestPairingMutationHookResult = ReturnType<typeof useRescheduleTestPairingMutation>;
export type RescheduleTestPairingMutationResult = Apollo.MutationResult<Types.RescheduleTestPairingMutation>;
export type RescheduleTestPairingMutationOptions = Apollo.BaseMutationOptions<Types.RescheduleTestPairingMutation, Types.RescheduleTestPairingMutationVariables>;
export const CompleteTestPairingDocument = gql`
    mutation CompleteTestPairing($id: ID!, $runnerVisitFeedback: String!, $runnerVisitNotes: String!, $runnerWantsPairing: Boolean!) {
  response: completeTestPairing(
    id: $id
    runnerVisitFeedback: $runnerVisitFeedback
    runnerVisitNotes: $runnerVisitNotes
    runnerWantsPairing: $runnerWantsPairing
  ) {
    testPairing {
      ...TestPairing
    }
  }
}
    ${TestPairingFragmentDoc}`;
export type CompleteTestPairingMutationFn = Apollo.MutationFunction<Types.CompleteTestPairingMutation, Types.CompleteTestPairingMutationVariables>;

/**
 * __useCompleteTestPairingMutation__
 *
 * To run a mutation, you first call `useCompleteTestPairingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteTestPairingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeTestPairingMutation, { data, loading, error }] = useCompleteTestPairingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      runnerVisitFeedback: // value for 'runnerVisitFeedback'
 *      runnerVisitNotes: // value for 'runnerVisitNotes'
 *      runnerWantsPairing: // value for 'runnerWantsPairing'
 *   },
 * });
 */
export function useCompleteTestPairingMutation(baseOptions?: Apollo.MutationHookOptions<Types.CompleteTestPairingMutation, Types.CompleteTestPairingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CompleteTestPairingMutation, Types.CompleteTestPairingMutationVariables>(CompleteTestPairingDocument, options);
      }
export type CompleteTestPairingMutationHookResult = ReturnType<typeof useCompleteTestPairingMutation>;
export type CompleteTestPairingMutationResult = Apollo.MutationResult<Types.CompleteTestPairingMutation>;
export type CompleteTestPairingMutationOptions = Apollo.BaseMutationOptions<Types.CompleteTestPairingMutation, Types.CompleteTestPairingMutationVariables>;
export const TestPairingPageDocument = gql`
    query TestPairingPage($id: ID!) {
  testPairing(id: $id) {
    id
    coach {
      ...CoachReferral
    }
    acceptedAt
    rejectedAt
    cancelledAt
    scheduledAt
    completedAt
    notesForRunner
    runnerVisitFeedback
    runnerVisitNotes
    runnerWantsPairing
    rejectionNotes
    cancellationNotes
  }
}
    ${CoachReferralFragmentDoc}`;

/**
 * __useTestPairingPageQuery__
 *
 * To run a query within a React component, call `useTestPairingPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestPairingPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestPairingPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTestPairingPageQuery(baseOptions: Apollo.QueryHookOptions<Types.TestPairingPageQuery, Types.TestPairingPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.TestPairingPageQuery, Types.TestPairingPageQueryVariables>(TestPairingPageDocument, options);
      }
export function useTestPairingPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.TestPairingPageQuery, Types.TestPairingPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.TestPairingPageQuery, Types.TestPairingPageQueryVariables>(TestPairingPageDocument, options);
        }
export type TestPairingPageQueryHookResult = ReturnType<typeof useTestPairingPageQuery>;
export type TestPairingPageLazyQueryHookResult = ReturnType<typeof useTestPairingPageLazyQuery>;
export type TestPairingPageQueryResult = Apollo.QueryResult<Types.TestPairingPageQuery, Types.TestPairingPageQueryVariables>;